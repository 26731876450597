export enum AuthenticateErrorCodes {
	DEED_QUESTIONNAIRE_NOT_FOUND = 'ERROR_ACCOUNT_10010',
	NOT_VERIFIED = 'ERROR_ACCOUNT_10007',
	UNAUTHORIZED = 'UNAUTHORIZED',
	UNKNOWN = 'ERROR_UNKNOWN',
}

export interface Authenticate {
	token?: string;
	expiresAt?: string;
}
